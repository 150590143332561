<template>
  <TableActionsComponent
    :editing="editing"
    :commentValue="commentaire"
    :commentDisabled="enlevementManquant"
    @cancel="$emit('cancel')"
    @validate="$emit('validate')"
    @comment="$emit('comment')"
    @view="$emit('view')"
    @edit="$emit('edit')"
  >
    <b-dropdown-item
      class="d-block d-md-none"
      :disabled="enlevementManquant"
      @click="$emit('view')"
    >
      <b-icon-eye></b-icon-eye>
      Visualiser l'enl&egrave;vement
    </b-dropdown-item>
    <b-dropdown-item class="d-block d-md-none" @click="$emit('edit')">
      <b-icon-pencil-square></b-icon-pencil-square>
      Modifier l'enl&egrave;vement
    </b-dropdown-item>
    <b-dropdown-item :disabled="enlevementManquant" @click="$emit('duplicate')">
      <b-icon-files></b-icon-files>
      Dupliquer l'enl&egrave;vement
    </b-dropdown-item>
    <b-dropdown-item :disabled="enlevementManquant" @click="$emit('delete')">
      <b-icon-trash></b-icon-trash>
      Annuler cette saisie
    </b-dropdown-item>
  </TableActionsComponent>
</template>

<script>
import TableActionsComponent from "./TableActionsComponent.vue";
export default {
  name: "EnlevementActionsComponent",
  components: { TableActionsComponent },
  props: ["item", "editing"],
  computed: {
    commentaire() {
      return this.item.enlevement?.commentaire;
    },
    enlevementManquant() {
      return !this.item.enlevement?.id;
    },
  },
};
</script>
