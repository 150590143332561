<template>
  <section>
    <CustomCheckboxComponent
      v-if="!readOnly || enMoisson"
      ref="checkbox"
      :label="label ? 'Enlèvement en moisson : ' : 'Moisson : '"
      :labelCols="labelCols"
      :disabled="readOnly"
      :checked="enMoisson"
      @input="enMoissonChanged"
    ></CustomCheckboxComponent>
    <template v-if="!enMoisson">
      <CustomSelectComponent
        v-if="societeProbiolor"
        ref="validatedSelect"
        :label="label"
        :labelCols="labelCols"
        :readOnly="readOnly"
        :readOnlyValue="isoSqlDateToFrenchDate"
        :quickEdit="quickEdit"
        rules="required"
        :options="optionsProbiolor"
        :value="dateEnlevement"
        @input="(date) => $emit('dateChanged', date)"
      ></CustomSelectComponent>
      <CustomInputDateComponent
        v-else
        ref="validatedInput"
        :label="label"
        :labelCols="labelCols"
        :readOnly="readOnly"
        :quickEdit="quickEdit"
        :rules="`required|date_between:${codeRecolte}-07-01,${
          parseInt(codeRecolte) + 1
        }-06-30`"
        :value="dateEnlevement"
        @input="(date) => $emit('dateChanged', date)"
      ></CustomInputDateComponent>
    </template>
  </section>
</template>

<script>
import CustomCheckboxComponent from "./CustomCheckboxComponent.vue";
import CustomSelectComponent from "./CustomSelectComponent.vue";
import CustomInputDateComponent from "./CustomInputDateComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "InputDateEnlevementComponent",
  components: {
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomInputDateComponent,
  },
  /**
   * enMoisson = valeur du switch "En moisson"
   * dateEnlevement = valeur de la date d'enlèvement
   * label = label du form group date d'enlèvement
   * labelCols = labelCols du form group date d'enlèvement
   * readOnly = conditionne l'affichage en lecture seule (false par défaut)
   * quickEdit = indique si prend en charge l'event "edit" (false par défaut)
   */
  props: [
    "enMoisson",
    "dateEnlevement",
    "label",
    "labelCols",
    "readOnly",
    "quickEdit",
  ],
  computed: {
    codeRecolte() {
      return this.$route.params.codeRecolte;
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    societeProbiolor() {
      return this.exploitationCourante?.codeSociete === "PROBIOLOR";
    },
    optionsProbiolor() {
      let dateFormatter = this.isoSqlDateToFrenchDate;
      let options = this.$store.getters["ref/datesEnlevement"](
        "PROBIOLOR",
        this.codeRecolte
      )?.map((d) => ({ value: d, text: dateFormatter(d) }));
      if (
        this.dateEnlevement &&
        !options.find((o) => o.value === this.dateEnlevement)
      ) {
        options = [
          {
            value: this.dateEnlevement,
            text: this.isoSqlDateToFrenchDate(this.dateEnlevement),
            disabled: true,
          },
          ...options,
        ];
      }
      return options;
    },
  },
  methods: {
    isoSqlDateToFrenchDate: UtilsService.isoSqlDateToFrenchDate,
    enMoissonChanged(enMoisson) {
      this.$emit("enMoissonChanged", enMoisson);
      if (enMoisson === true) {
        // Si on repasse en enlèvement moisson, l'API s'occupe de la date
        return;
      }
      if (this.societeProbiolor) {
        // Sinon on oblige à ressaisir pour Probiolor
        this.$emit("dateChanged", null);
      } else if (!this.dateEnlevement) {
        // Et on met le 01/09 pour les autres uniquement si pas de valeur
        this.$emit("dateChanged", `${this.codeRecolte}-09-01`);
      }
    },
    focus() {
      this.$refs.checkbox?.focus();
    },
    async validate() {
      // Sélection restreinte pour Probiolor
      await this.$refs.validatedSelect?.validate();
      // Sélection libre (cas général)
      await this.$refs.validatedInput?.validate();
    },
  },
};
</script>
