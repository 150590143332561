<template>
  <div class="ml-5">
    <b-table
      :id="`enlevement-culture-${culture.id}`"
      table-class="mb-0"
      hover
      :fields="fields"
      :items="culture.emblavements"
      sort-by="parcelle.libelleParcelle"
      :sort-desc="false"
      @keydown.native.esc="cancel"
      @keydown.native.enter="onEnter"
    >
      <template #cell(quantiteACollecter)="{ item }">
        <span :id="`quantite-emb-${item.id}`">
          <MontantComponent :value="item.quantiteACollecter"></MontantComponent>
        </span>
        <QuantiteCollecteTooltip
          :target="`quantite-emb-${item.id}`"
          :quantitePrevisionnelle="item.quantitePrevisionnelle"
          :quantiteMoissonnee="item.quantiteMoissonnee"
          :quantiteAutoconsommee="item.quantiteAutoconsommee"
        ></QuantiteCollecteTooltip>
      </template>
      <template #cell(modeEnlevement)="{ item }">
        <section v-if="item.enlevement">
          <SelectModeComponent
            v-if="editing === item.id"
            :ref="`selectMode_${item.id}`"
            label=""
            :societe="exploitationCourante.codeSociete"
            rules="required"
            v-model="item.enlevement.codeMode"
          ></SelectModeComponent>
          <BadgeComponent
            v-else
            :text="item.enlevement.mode.libelle"
            :variant="`list${item.enlevement.mode.ordre}`"
            @click="quickEdit(item.id)"
          ></BadgeComponent>
        </section>
        <b-link v-else href="#" @click="$emit('edit', item)">
          <em v-if="culture.enlevement">
            Enl&egrave;vement &agrave; la culture
          </em>
          <span v-else>Saisir un enl&egrave;vement</span>
        </b-link>
      </template>
      <template #cell(dateEnlevement)="{ item }">
        <section v-if="item.enlevement && item.enlevement.codeMode !== 'NONE'">
          <InputDateEnlevementComponent
            v-if="editing === item.id"
            :ref="`inputDate_${item.id}`"
            label=""
            :readOnly="['R1', 'G4'].includes(item.produit.typeCulture)"
            :enMoisson="item.enlevement.enMoisson"
            @enMoissonChanged="
              (checked) => (item.enlevement.enMoisson = checked)
            "
            :dateEnlevement="item.enlevement.dateLimite"
            @dateChanged="
              (value) => {
                // FIXME Rustine pour gérer la date souhaitée
                item.enlevement.dateSouhaitee = value;
                item.enlevement.dateLimite = value;
              }
            "
          ></InputDateEnlevementComponent>
          <b-link
            v-else
            class="quick-edit-link"
            href="#"
            @click="quickEdit(item.id)"
          >
            {{
              item.enlevement.enMoisson
                ? "En moisson"
                : isoSqlDateToFrenchDate(item.enlevement.dateLimite)
            }}
          </b-link>
        </section>
      </template>
      <template #cell(actions)="{ item }">
        <EnlevementActionsComponent
          :item="item"
          :editing="editing === item.id"
          @cancel="cancel()"
          @validate="validate(item)"
          @comment="$emit('comment', item)"
          @view="$emit('view', item)"
          @edit="$emit('edit', item)"
          @duplicate="$emit('duplicate', item)"
          @delete="$emit('delete', item)"
        ></EnlevementActionsComponent>
      </template>
    </b-table>
  </div>
</template>

<script>
import MontantComponent from "./MontantComponent.vue";
import QuantiteCollecteTooltip from "./controls/QuantiteCollecteTooltip.vue";
import BadgeComponent from "./BadgeComponent.vue";
import SelectModeComponent from "./inputs/SelectModeComponent.vue";
import InputDateEnlevementComponent from "./inputs/InputDateEnlevementComponent.vue";
import EnlevementActionsComponent from "./controls/EnlevementActionsComponent.vue";
import UtilsService from "../services/utils.service";
export default {
  name: "EnlevementDetailsComponent",
  components: {
    MontantComponent,
    QuantiteCollecteTooltip,
    BadgeComponent,
    SelectModeComponent,
    InputDateEnlevementComponent,
    EnlevementActionsComponent,
  },
  props: ["culture"],
  data: () => ({
    fields: [
      {
        key: "parcelle.libelleParcelle",
        label: "Parcelle",
      },
      {
        key: "produit.libelle",
        label: "Culture",
      },
      {
        key: "quantiteACollecter",
        label: "Quantité à collecter (t)",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        key: "modeEnlevement",
        label: "Mode d'enlèvement",
      },
      {
        key: "dateEnlevement",
        label: "Date d'enlèvement au plus tard",
      },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "160px" },
      },
    ],
    editing: null,
  }),
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  methods: {
    isoSqlDateToFrenchDate: UtilsService.isoSqlDateToFrenchDate,
    async quickEdit(idEmblavement) {
      this.editing = idEmblavement;
      if (!idEmblavement) {
        return;
      }
      await this.$nextTick();
      this.$refs[`inputDate_${idEmblavement}`]?.focus();
      this.$refs[`selectMode_${idEmblavement}`]?.focus();
    },
    async onEnter() {
      let target = this.culture.emblavements.find((t) => t.id === this.editing);
      if (target) {
        this.validate(target);
      }
    },
    cancel() {
      this.editing = null;
      this.$emit("cancel");
    },
    async validate(emblavement) {
      await this.$refs[`selectMode_${emblavement.id}`].validate();
      await this.$refs[`inputDate_${emblavement.id}`]?.validate();
      this.editing = null;
      this.$emit("validate", emblavement.enlevement);
    },
  },
};
</script>
