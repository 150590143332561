<template>
  <b-card
    class="mobile-card"
    header-bg-variant="light"
    v-b-toggle="`culture_${item.id}_collapse culture_${item.id}_button`"
  >
    <template #header>
      {{ item.libelleProduit }}
      <MelangeIcon v-if="item.isMelangeEspeces"></MelangeIcon>
      <div v-else class="label font-down">
        {{ item.libelleVariete }}
        <MelangeIcon v-if="item.isMelangeVarietes"></MelangeIcon>
      </div>
    </template>
    <b-container>
      <b-row align-v="center">
        <b-col
          cols="auto"
          class="bg-light bg-left font-up"
          style="width: 80px; border-radius: 0 !important"
        >
          <div class="w-100">
            <div>{{ minDate }}</div>
            <div v-if="maxDate !== minDate" style="border-top: 1px solid black">
              {{ maxDate }}
            </div>
          </div>
        </b-col>
        <b-col class="font-down">
          <div>
            <AnomalieIcon
              :anomaliesCulture="item.anomalies"
              :anomaliesEnlevement="
                item.enlevement ? item.enlevement.anomalies : []
              "
            ></AnomalieIcon>
            <BadgeComponent
              v-if="saisieCulture"
              :text="item.enlevement.mode.libelle"
              :variant="`list${item.enlevement.mode.ordre}`"
            ></BadgeComponent>
            <em v-else-if="saisieEmblavement">
              Enl&egrave;vement &agrave; la parcelle
            </em>
            <template v-else>Saisir un enl&egrave;vement</template>
          </div>
          <div class="label">
            <MontantComponent
              :value="item.quantiteACollecter"
            ></MontantComponent>
            t &agrave; collecter
          </div>
          <b-collapse
            :id="`culture_${item.id}_collapse`"
            class="label text-center"
            visible
          >
            <b-icon-chevron-down></b-icon-chevron-down>
            Voir les parcelles
          </b-collapse>
        </b-col>
        <b-col cols="auto" class="px-0" @click.stop>
          <EnlevementActionsComponent
            :item="item"
            @view="$emit('view')"
            @edit="$emit('edit')"
            @duplicate="$emit('duplicate', item.enlevement)"
            @delete="$emit('delete')"
          ></EnlevementActionsComponent>
        </b-col>
      </b-row>
      <b-collapse
        :id="`culture_${item.id}_collapse`"
        style="border-top: 1px solid lightgrey"
      >
        <b-row v-for="emb in item.emblavements" :key="emb.id" align-v="center">
          <b-col
            cols="auto"
            class="bg-light bg-left font-up"
            style="width: 80px; border-radius: 0 !important"
          >
            <div v-if="saisieEmblavement" class="w-100">
              {{ getDate(emb) }}
            </div>
          </b-col>
          <b-col class="py-0 pt-1 font-down">
            <div class="rounded bg-light">
              {{ emb.parcelle.libelleParcelle }}
              (<MontantComponent
                :value="emb.quantiteACollecter"
              ></MontantComponent>
              t)
            </div>
            <div v-if="item.isMelangeEspeces">
              {{ emb.produit.libelle }}
            </div>
            <div v-else-if="item.isMelangeVarietes">
              {{ emb.variete.libelle }} <MelangeIcon></MelangeIcon>
            </div>
            <div>
              <em v-if="saisieCulture">
                Enl&egrave;vement &agrave; la culture
              </em>
              <BadgeComponent
                v-else-if="emb.enlevement && emb.enlevement.id"
                :text="emb.enlevement.mode.libelle"
                :variant="`list${emb.enlevement.mode.ordre}`"
              ></BadgeComponent>
              <template v-else>Saisir un enl&egrave;vement</template>
            </div>
          </b-col>
          <b-col cols="auto" class="px-0" @click.stop>
            <EnlevementActionsComponent
              :item="emb"
              @view="$emit('viewEmb', emb)"
              @edit="$emit('editEmb', emb)"
              @duplicate="$emit('duplicate', emb.enlevement)"
              @delete="$emit('deleteEmb', emb)"
            ></EnlevementActionsComponent>
          </b-col>
        </b-row>
      </b-collapse>
    </b-container>
  </b-card>
</template>

<script>
import AnomalieIcon from "../controls/AnomalieIcon.vue";
import MontantComponent from "../MontantComponent.vue";
import BadgeComponent from "../BadgeComponent.vue";
import EnlevementActionsComponent from "../controls/EnlevementActionsComponent.vue";
// import ViewEditComponent from "../controls/ViewEditComponent.vue";
export default {
  name: "EnlevementCard",
  components: {
    AnomalieIcon,
    MontantComponent,
    BadgeComponent,
    EnlevementActionsComponent,
    // ViewEditComponent,
  },
  props: ["item"],
  computed: {
    saisieCulture() {
      return !!this.item.enlevement?.id;
    },
    saisieEmblavement() {
      return !!this.item.emblavements.find((e) => e.enlevement?.id);
    },
    minMaxDate() {
      return this.item.emblavements
        .map((e) => e.enlevement?.dateLimite)
        .filter((d) => !!d)
        .reduce(
          (acc, next) => {
            if (!acc.min || next.localeCompare(acc.min) < 0) {
              acc.min = next;
            }
            if (!acc.max || next.localeCompare(acc.max) > 0) {
              acc.max = next;
            }
            return acc;
          },
          {
            min: this.item.enlevement?.dateLimite,
            max: this.item.enlevement?.dateLimite,
          }
        );
    },
    minDate() {
      let date = this.minMaxDate.min;
      if (!date) {
        return "";
      }
      date = date.split("-");
      return `${date[2]}/${date[1]}`;
    },
    maxDate() {
      let date = this.minMaxDate.max;
      if (!date) {
        return "";
      }
      date = date.split("-");
      return `${date[2]}/${date[1]}`;
    },
  },
  methods: {
    getDate(emb) {
      if (!emb.enlevement?.dateLimite) {
        return "";
      }
      let split = emb.enlevement.dateLimite.split("-");
      return `${split[2]}/${split[1]}`;
    },
  },
};
</script>
