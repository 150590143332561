<template>
  <CustomSelectComponent
    ref="select"
    :label="label"
    :labelCols="labelCols"
    :readOnly="readOnly"
    :readOnlyValue="readOnlyValue"
    :disabled="disabled"
    :rules="rules"
    :options="options"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  >
    <slot></slot>
  </CustomSelectComponent>
</template>

<script>
import CustomSelectComponent from "./CustomSelectComponent.vue";
import ReferentielService from "../../services/referentiel.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "SelectModeComponent",
  components: { CustomSelectComponent },
  props: {
    // valeur du select (type sans importance)
    value: undefined,
    // true pour usage de l'API ; sinon usage du store
    fetch: [Boolean, String],
    // true pour ajouter une option "Toutes" (false par défaut)
    all: [Boolean, String],
    // code société pour filtrage (null pour tous)
    societe: String,
    // propriété label du form-group
    label: {
      type: String,
      default: "Mode d'enlèvement",
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // true pour affichage lecture seule (false par défaut)
    readOnly: [Boolean, String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  watch: {
    value: "load",
    fetch: "load",
    all: "load",
  },
  data: () => ({
    options: [],
  }),
  computed: {
    readOnlyValue() {
      return this.$store.getters["ref/modeEnlevement"](this.value)?.libelle;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let options = [];

      // Chargement des données selon la source demandée
      if (this.fetch === true || this.fetch === "true") {
        await ReferentielService.getAllModesEnlevement().then(
          (response) => (options = response.data)
        );
      } else {
        options = this.$store.getters["ref/modesEnlevement"](this.societe);
      }

      // Transformation en options et tri
      options = options
        .filter((m) => m.actif)
        .sort(UtilsService.sortByNumberProperty("ordre"))
        .map((m) => ({ value: m.code, text: m.libelle }));

      // Ajout de l'option courante désactivée si nécessaire
      if (this.value && !options.find((o) => o.value === this.value)) {
        let mode = this.$store.getters["ref/modeEnlevement"](this.value);
        options = [
          {
            value: this.value,
            text: mode?.libelle || "Inconnu",
            disabled: true,
          },
          ...options,
        ];
      }

      // Ajout de l'option Tous si nécessaire
      if (this.all === true || this.all === "true") {
        options = [{ value: null, text: "Tous" }, ...options];
      }

      this.options = options;
    },
    focus() {
      this.$refs.select.focus();
    },
    async validate() {
      await this.$refs.select.validate();
    },
  },
};
</script>
