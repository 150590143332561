<template>
  <b-form-group
    :id="`group-${id}`"
    :label="computedLabel"
    :label-for="`input-${id}`"
    :label-cols="labelCols"
    :description="description"
  >
    <b-form-tags :id="`input-${id}`" v-model="selected" no-outer-focus>
      <ul
        v-if="selectedCultures.length > 0"
        class="list-inline d-inline-block mb-2"
      >
        <li
          v-for="culture in selectedCultures"
          :key="culture.id"
          class="list-inline-item"
        >
          <b-form-tag @remove="unselectCulture(culture)" variant="primary">
            {{ culture.libelleProduit }} {{ culture.libelleVariete }}
          </b-form-tag>
        </li>
      </ul>
      <b-dropdown
        menu-class="w-100"
        size="sm"
        block
        toggle-class="rounded-pill"
        variant="outline-primary"
      >
        <template #button-content>Choisir culture(s)</template>
        <b-dropdown-form @submit.stop.prevent="() => {}">
          <b-form-group
            :id="`group-filtre-${id}`"
            label="Filtre"
            :label-for="`input-filtre-${id}`"
            label-cols="auto"
            class="mb-0"
            label-size="sm"
          >
            <b-form-input
              :id="`input-filtre-${id}`"
              :name="`input-filtre-${id}`"
              type="search"
              size="sm"
              autocomplete="off"
              v-model="filtre"
            ></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button
          v-for="culture in options"
          :key="culture.id"
          @click="selectCulture(culture)"
        >
          {{ culture.libelleProduit }} {{ culture.libelleVariete }}
        </b-dropdown-item-button>
        <b-dropdown-text v-if="options.length === 0">
          Aucune culture ne correspond &agrave; vos crit&egrave;res de recherche
        </b-dropdown-text>
      </b-dropdown>
    </b-form-tags>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import UtilsService from "../../services/utils.service";
export default {
  name: "SelectCulturesComponent",
  /**
   * value = valeur du select (tableau d'objets culture)
   * label = label du select
   * labelCols = propriété label-cols du form-group
   * description = description du form-group
   * anomalie = code anomalie requis pour afficher la culture
   */
  props: ["value", "label", "labelCols", "description", "anomalie"],
  data() {
    return {
      id: `culture-${uuidv4()}`,
      filtre: "",
      selected: [],
    };
  },
  computed: {
    computedLabel() {
      return this.label ?? "Culture(s)";
    },
    computedFiltre() {
      return this.filtre?.trim().toLowerCase() || "";
    },
    options() {
      // Rechercher les cultures vides pouvant correspondre
      return UtilsService.deepCopy(this.$store.state.decl.cultures)
        .map((c) => {
          // Filtrer la culture si elle est déjà sélectionnée
          if (this.selected.includes(c.id)) {
            return null;
          }

          // Filtrer la culture si elle ne correspond pas à la recherche
          if (
            !c.libelleProduit.toLowerCase().includes(this.computedFiltre) &&
            !c.libelleVariete.toLowerCase().includes(this.computedFiltre)
          ) {
            return null;
          }

          // Filtrer la culture si elle n'a plus de parcelle rattachée
          if (
            !this.$store.state.decl.parcelles.find((p) => p.idCulture === c.id)
          ) {
            return null;
          }

          // Si correspondance, vérifier que la culture doit être saisie
          // (i.e. anomalie enlevement manquant)
          if (this.anomalie && !c.anomalies.includes(this.anomalie)) {
            return null;
          }

          return c;
        })
        .filter((c) => !!c)
        .sort(
          UtilsService.sortBy(
            UtilsService.sortByStringProperty("libelleProduit"),
            UtilsService.sortByStringProperty("libelleVariete")
          )
        );
    },
    selectedCultures() {
      return UtilsService.deepCopy(this.$store.state.decl.cultures).filter(
        (c) => this.selected.includes(c.id)
      );
    },
  },
  mounted() {
    this.selected = this.value?.map((p) => p.id);
  },
  methods: {
    async selectCulture(culture) {
      this.selected.push(culture.id);
      await this.$nextTick();
      return this.$emit("input", this.selectedCultures);
    },
    async unselectCulture(culture) {
      this.selected = this.selected.filter((id) => id !== culture.id);
      await this.$nextTick();
      return this.$emit("input", this.selectedCultures);
    },
  },
};
</script>
